import cookiesActionTypes from "./cookies.types";

const INITIAL_STATE = {
    cookiesModal: false,
}

const cookiesReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case cookiesActionTypes.COOKIES_MODAL_SHOW:
            return{
                ...state,
                cookiesModal: true
            }
        case cookiesActionTypes.COOKIES_MODAL_HIDE:
            return{
                ...state,
                cookiesModal: false
            }
        default:
            return state;
    }
}


export default cookiesReducer;
