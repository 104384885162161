import campaignFormActionTypes from "./campaign-form.types";

const INITIAL_STATE = {
    campaignModal: false,
}

const campaignFormReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        case campaignFormActionTypes.CAMPAIGN_MODAL_SHOW:
            return{
                ...state,
                campaignModal: true
            }
        case campaignFormActionTypes.CAMPAIGN_MODAL_HIDE:
            return{
                ...state,
                campaignModal: false
            }
        default:
            return state;
    }
}


export default campaignFormReducer;
