import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'

import cookiesReducer from "./cookies/cookies.reducer";
import campaignFormReducer from "./campaign-form/campaign-form.reducer";


const persistConfig = {
    key: 'root',
    storage,
    whitelist:[]

}

const rootReducer = combineReducers({
    cookies:cookiesReducer,
    campaign:campaignFormReducer
})

export default rootReducer;
//export default persistReducer(persistConfig, rootReducer);
