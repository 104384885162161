import React from 'react'
import {Provider} from "react-redux";
import {store} from "./src/redux/store";

import "./src/styles/global.scss"

import 'swiper/css';
import 'swiper/css/navigation';


export const wrapRootElement = ({ element }) => {
    return(
        <Provider store={store}>
            {element}
        </Provider>
    )
}
